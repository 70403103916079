<template>
    <div class="loginwrap">
        <div class="backbox" @click="back">
            <span class="el-icon-arrow-left"></span>返回
        </div>
        <div class="loginform">
            <div class="logintype">
                <el-tabs v-model="activeName" @tab-click="tabchang">
                  <el-tab-pane label="密码登录" name="0">
                    <div class="passwordform">
                        <el-input class="inputform input_item" v-model="passwordform.user" placeholder="请输入账号/手机号">
                            <template slot="prepend"><span class="el-icon-user input_logo"></span></template>
                        </el-input>
                        <el-input class="inputform input_item" v-model="passwordform.password" placeholder="请输入密码" type='password'>
                            <template slot="prepend"><span class="el-icon-lock input_logo"></span></template>
                        </el-input>
                        <div class="loginrow">
                            <el-checkbox v-model="passwordform.jzmm">记住密码</el-checkbox>
                        </div>
                        <el-button type="warning" round size='medium' class="loginbtn" @click="loginpwd">登录</el-button>
                        <div class="loginrow">
                            没有账号？去
                            <span class="zctext" @click="zc">注册</span>
                            <div class="wjmmtext" @click="forgetpwd">忘记密码</div>
                        </div>
                        <!-- <el-divider content-position="center">第三方登录</el-divider>
                        <div class="loginrow">
                            <span class="el-icon-chat-dot-round icon"></span>
                        </div> -->
                        <div class="loginrow">
                            <el-checkbox v-model="passwordform.yhxy">登录即代表同意</el-checkbox>
                            <span class="yhxyrow">
                                《用户服务协议》《隐私政策》
                            </span>
                        </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="短信登录" name="1">
                    <div class="duanxinform">
                        <el-input class="inputform input_item" v-model="duanxinform.user" placeholder="请输入手机号">
                            <template slot="prepend"><span class="el-icon-user input_logo"></span></template>
                        </el-input>
                        <el-input class="inputform input_item" v-model="duanxinform.password" placeholder="请输入验证码">
                            <template slot="prepend"><span class="el-icon-lock input_logo"></span></template>
                            <el-button slot="append" @click="getyzm">{{yzmconfig.time == 0 ? '获取验证码' : yzmconfig.time + 's'}}</el-button>
                        </el-input>
                        <el-button type="warning" round size='medium' class="loginbtn" @click="loginduanxin">登录</el-button>
                        <div class="loginrow">
                            没有账号？去
                            <span class="zctext" @click="zc">注册</span>
                        </div>
                        <!-- <el-divider content-position="center">第三方登录</el-divider>
                        <div class="loginrow">
                            <span class="el-icon-chat-dot-round icon"></span>
                        </div> -->
                        <div class="loginrow">
                            <el-checkbox v-model="duanxinform.yhxy">登录即代表同意</el-checkbox>
                            <span class="yhxyrow">
                                《用户服务协议》《隐私政策》
                            </span>
                        </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import { login , loginyzm , getyzmapi } from '@/api/login.js';
import { Message } from 'element-ui';
import md5 from 'md5';

export default {
    data() {
        return {
            activeName: '0',
            passwordform:{
                user: '',
                password: '',
                jzmm: false,
                yhxy: true,
            },
            duanxinform:{
                user: '',
                password: '',
                yhxy: true,
            },
            yzmconfig:{
                time: 0,
            },
        }
    },
    methods:{
        tabchang(e){
            console.log(e)
        },
        back(){
            this.$router.go(-1);
        },
        forgetpwd(){
            this.$router.push({
                path: '/forgetpwd'
            });
        },
        loginpwd(){
            if(!this.passwordform.yhxy){
                return Message({
                         showClose: true,
                         message: '请先确认已阅读用户协议',
                         type: 'error'
                       });
            }
            if(!(this.passwordform.user&&this.passwordform.password)){
                return Message({
                         showClose: true,
                         message: '账号密码不能为空',
                         type: 'error'
                       });
            }
            let form = {
                wxPhone: this.passwordform.user,
                pswd: md5(this.passwordform.password),
                apartment_Sid: this.$store.state.project.projectid,
            };
            login(form).then((res) => {
                if(res.success != 2600){
                    Message({
                      showClose: true,
                      message: res.message,
                      type: 'error'
                    });
                }
                else{
                    //登录成功
                    this.$store.commit('user/set_token',{
                      data: true,
                    })
                    this.$store.commit('user/set_userinfo',{
                      data: res.data.result[0]
                    })
                    if(this.passwordform.jzmm){
                        this.$store.commit('user/set_userpwd',{
                          data: {
                              username: this.passwordform.user,
                              password: this.passwordform.password,
                          }
                        })
                    }
                    else{
                        this.$store.commit('user/set_cleanuserpwd');
                    }
                    this.$router.replace({
                        path: '/'
                    })
                }
            })
        },
        loginduanxin(){
             if(!this.duanxinform.yhxy){
                return Message({
                         showClose: true,
                         message: '请先确认已阅读用户协议',
                         type: 'error'
                       });
            }
            if(!(this.duanxinform.user&&this.duanxinform.password)){
                return Message({
                         showClose: true,
                         message: '账号验证码不能为空',
                         type: 'error'
                       });
            }
            let form = {
                wxPhone: this.duanxinform.user,
                code: this.duanxinform.password,
                apartment_Sid: this.$store.state.project.projectid,
            };
            loginyzm(form).then((res) => {
                if(res.success != 2600){
                    Message({
                      showClose: true,
                      message: res.message,
                      type: 'error'
                    });
                }
                else{
                    //登录成功
                    this.$store.commit('user/set_token',{
                      data: true,
                    })
                    this.$store.commit('user/set_userinfo',{
                      data: res.data.result[0]
                    })
                    if(this.passwordform.jzmm){
                        this.$store.commit('user/set_userpwd',{
                          data: {
                              username: this.duanxinform.user,
                          }
                        })
                    }
                    this.$router.replace({
                        path: '/'
                    })
                }
            })
        },
        init(){
            if(this.$store.state.user.userpwd){
                this.passwordform.jzmm = true;
            }
            else{
                this.passwordform.jzmm = false;
            }
            if(this.$store.state.user.userpwd){
                this.passwordform.user = this.$store.state.user.userpwd.username;
                this.passwordform.password = this.$store.state.user.userpwd.password;
            }
        },
        zc(){
            this.$router.push({
                path: 'regist',
            })
        },
        getyzm(){
            if(this.yzmconfig.time == 0){
                if(!this.duanxinform.user){
                    return Message({
                             showClose: true,
                             message: '请填写手机号',
                             type: 'error'
                           });
                }
                getyzmapi({
                    owner_Phone: this.duanxinform.user
                }).then((res) => {
                    if(res.state == '2600'){
                        this.yzmconfig.time = 60;
                        let yzmtime = setInterval(() => {
                            if(this.yzmconfig.time > 0){
                                this.yzmconfig.time = --this.yzmconfig.time;
                            }else{
                                clearInterval(yzmtime);
                            }
                        },1000)
                    }
                    else{
                        return Message({
                                 showClose: true,
                                 message: res.message,
                                 type: 'error'
                               });
                    }
                })
            }
        },
    },
    beforeMount(){
        this.init();
    },
}
</script>

<style scoped>

    .loginwrap{
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/login.jpg");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        position: relative;
    }
    
    .backbox{
        position: absolute;
        top: 20px;
        left: 20px;
        color: #FFFFFF;
        font-weight: 800;
        cursor: pointer;
    }

    .el-icon-arrow-left{
        margin-right: 10px;
    }

    /* 表单 */
    .loginform{
        width: 300px;
        /* height: 450px; */
        height: 400px;
        background-color: #FFFFFF;
        /* margin-left: 300px; */
        margin-left: 14%;
        padding: 20px 50px;
        font-size: 14px;
        border-radius: 12px;
    }

    .inputform{
        margin: 10px 0px;
    }

    .loginbtn{
        width: 100%;
        /* margin-top: 20px; */
    }

    .loginrow{
        margin: 20px 0px;
    }

    .wjmmtext{
        display: inline-block;
        float: right;
        cursor: pointer;
    }
    
    .zctext{
        /* color: #409eff; */
        font-weight: 700;
        cursor: pointer;
    }

    /* 微信logo */
    .passwordform .loginrow:nth-child(7),
    .duanxinform .loginrow:nth-child(6){
        text-align: center;
    }

    .passwordform .loginrow:nth-child(6),
    .duanxinform .loginrow:nth-child(5){
        margin-top: 40px;
    }

    .icon{
        font-size: 30px;
    }

    .yhxyrow{
        color: #606266;
        font-size: 12px;
    }

    .duanxinform .loginbtn{
        margin-top: 20px;
    }
    
    >>>.el-tabs__nav-scroll{
        display: flex;
        justify-content: center;
    }

    >>>.el-tabs__nav-wrap::after{
        display: none;
    }

    >>>.el-tabs__item{
        padding: 0 40px;
    }

    >>>.el-tabs__active-bar{
        background-color:  #721172;
    }

    >>>.el-tabs__item.is-active{
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
    }

    >>>.el-tabs__item:hover {
        color:  rgba(0, 0, 0, 1);
        cursor: pointer;
    }

    >>>.el-input__inner{
        border: none;
    }

    >>>.el-input-group__prepend,
    >>>.el-input-group__append{
        background-color: transparent;
        border: none;
        padding: 0px;
    }

    >>>.input_item{
        border-bottom: 1px solid #DCDFE6;
    }

    >>>.input_logo{
        font-size: 20px;
    }

    >>>.el-button--warning{
        background-color: #000000;
        border-color: #000000;
    }

    >>>.el-checkbox__input.is-checked .el-checkbox__inner{
        background-color: #000000;
        border-color: #000000;
    }

    >>>.el-checkbox__inner{
        border-color: #000000 !important;
    }

    >>>.el-checkbox__inner:hover {
        border-color: #000000;
    }

    >>>.el-checkbox__input.is-checked.el-checkbox__label{
        color: #000000;
    }

    >>>.el-checkbox__input.is-checked+.el-checkbox__label{
        color: #606266;
    }

</style>
